import { Outlet } from "react-router-dom";
import {
  NewTransferModule,
  OnlineReceiving,
  StockTakingCache,
} from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";
import { baseRoutes } from "../../../helpers/baseRoutes";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Online OPS",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: false,
      private: true,
      element: <Outlet />,
      children: [
        {
          path: routesMap.ONLINERECEIVING.path,
          name: "Online Receiving",
          key: routesMap.ONLINERECEIVING.path,
          commonRoute: true,
          belongsToSidebar: true,
          private: true,
          element: <OnlineReceiving />,
        },
        {
          path: routesMap.NEWTRANSFERMODULE.path,
          name: "New Transfer Module",
          key: routesMap.NEWTRANSFERMODULE.path,
          commonRoute: true,
          belongsToSidebar: true,
          private: true,
          element: <NewTransferModule />,
        },
        {
          path: routesMap.STOCKTAKINGCACHE.path,
          name: "STOCK TAKING CACHE",
          key: routesMap.STOCKTAKINGCACHE.path,
          commonRoute: true,
          belongsToSidebar: true,
          private: true,
          element: <StockTakingCache />,
        },
      ],
    },
  ];
}
