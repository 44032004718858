import { Outlet } from "react-router-dom";
import { baseRoutes } from "../../../helpers/baseRoutes";
import routesMap from "../../../routeControl/userRouteMap";
import { LocalPurchaseGrocery, VegLocalPurchase } from "../../../pages";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Transactions",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: false,
      private: true,
      element: <Outlet />,
      children: [
        {
          path: routesMap.VEGLOCALPURCHASE.path,
          name: "Veg Local Purchase",
          key: routesMap.VEGLOCALPURCHASE.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <VegLocalPurchase />,
        },
        {
          path: routesMap.LOCALPURCHASEGROCERY.path,
          name: "Local Purchase Grocery",
          key: routesMap.LOCALPURCHASEGROCERY.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <LocalPurchaseGrocery />,
        },
      ],
    },
  ];
}
