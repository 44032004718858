/* eslint-disable react-hooks/exhaustive-deps */
import { useRoutes } from "react-router-dom";
import "./App.css";
import { Suspense } from "react";
import { routes } from "./routes";
import { initDB } from "react-indexed-db-hook";
import { DBConfig } from "./IndexDBConfig";

import { Spin } from "antd";

initDB(DBConfig);

function App() {
  function RouteLayout({ path }) {
    const element = useRoutes(path);
    return element;
  }

  // useEffect(() => {
  //   const handleKeyDown = (event) => {
  //     if (event.ctrlKey && event.shiftKey && event.key === "R") {
  //       event.preventDefault();
  //     }
  //   };
  //   document.addEventListener("keydown", handleKeyDown);
  //   return () => {
  //     document.removeEventListener("keydown", handleKeyDown);
  //   };
  // }, []);

  return (
    <>
      <Suspense
        fallback={
          <div className="d-flex align-items-center justify-content-center h-100 mainLoader center">
            <Spin />
          </div>
        }
      >
        <RouteLayout path={routes()} />
      </Suspense>
    </>
  );
}

export default App;
