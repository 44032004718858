import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {
  BILLING_ROUTE: {
    path: `${baseRoutes.userBaseRoutes}`,
  },
  BILLING: {
    path: `${baseRoutes.userBaseRoutes}counter-sale`,
  },
};

export default accessRoute;
