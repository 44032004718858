import routesMap from "../../../routeControl/userRouteMap";
import { NoInternetConnection } from "../../../pages";
export default function route() {
  return [
    {
      path: routesMap.NOINTERNET.path,
      name: "No Internet Connection",
      key: routesMap.NOINTERNET.path,
      commonRoute: true,
      private: true,
      element: <NoInternetConnection />,
    },
  ];
}
