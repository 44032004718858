import { Outlet } from "react-router-dom";
import { baseRoutes } from "../../../helpers/baseRoutes";
import {
  CashHandover,
  OfferItemConvert,
  PosDeviceRegister,
  StockTakingApproval,
  WarehouseStockTaking,
} from "../../../pages";
import routesMap from "../../../routeControl/userRouteMap";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Reconcilation",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: true,
      private: true,
      element: <Outlet />,
      children: [
        // {
        //   path: routesMap.WAREHOUSESTOCKTAKING.path,
        //   name: "Warehouse Stock Tracking",
        //   key: routesMap.WAREHOUSESTOCKTAKING.path,
        //   belongsToSidebar: true,
        //   commonRoute: true,
        //   private: true,
        //   element: <WarehouseStockTaking />,
        // },
        {
          path: routesMap.CASHHANDOVER.path,
          name: "Cash Handover",
          key: routesMap.CASHHANDOVER.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <CashHandover />,
        },
        {
          path: routesMap.OFFERITEMCONVERT.path,
          name: "Offer Item Convert",
          key: routesMap.OFFERITEMCONVERT.path,
          belongsToSidebar: true,
          commonRoute: true,
          private: true,
          element: <OfferItemConvert />,
        },
        {
          path: routesMap.POSDEVICEREGISTER.path,
          name: "Pos Device Register",
          key: routesMap.POSDEVICEREGISTER.path,
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: <PosDeviceRegister />,
        },
        {
          path: routesMap.STOCKTAKINGAPPROVAL.path,
          name: "Stock Taking Approval",
          key: routesMap.STOCKTAKINGAPPROVAL.path,
          belongsToSidebar: false,
          commonRoute: true,
          private: true,
          element: <StockTakingApproval />,
        },
      ],
    },
  ];
}
