import { baseRoutes } from "../../../helpers/baseRoutes";

const accessRoute = {

  LOGIN: {
    path: `${baseRoutes.userBaseRoutes}`,
  },
  SIGNUP: {
    path: `${baseRoutes.userBaseRoutes}signup`,
  },
  OTP: {
    path: `${baseRoutes.userBaseRoutes}otp-verify`,
  },

};

export default accessRoute;
