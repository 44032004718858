import { baseRoutes } from "../../../helpers/baseRoutes";

export default function route() {
  return [
    {
      path: baseRoutes.userBaseRoutes,
      name: "Online Control",
      key: baseRoutes.userBaseRoutes,
      commonRoute: true,
      belongsToSidebar: false,
      private: true,
      element: "",
    },
  ];
}
